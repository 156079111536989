<template>
  <v-app>
    <div>
      <BreadcrumbComponent :items=items></BreadcrumbComponent>
      <PreloaderComponent :enable=enable></PreloaderComponent>
      <v-container fluid v-if='enable'>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Filtres
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="row">
                <div class="col-md-2"><v-select :items="users" item-value=id item-text=full_name placeholder="Agent"
                    v-model=user_id></v-select></div>
                <div class="col-md-1"><v-btn color="blue" text class="confirm_modal_button" v-on:click=filter
                    :loading="loading" :disabled="loading" @click="loader = 'loading'">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn></div>
                <div class="col-md-1"><v-btn color="blue" text class="reset_modal_button" v-on:click=reset
                    :loading="loading" :disabled="loading" @click="loader = 'loading'">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn></div>
                <div class="col-md-1"><v-btn color="red" text class="export_modal_button" v-on:click=exportPDF
                    :loading="loading" :disabled="loading" @click="loader = 'loading'">
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn></div>
                  <div class="col-md-1"><v-btn
                    color="blue"
                    text
                    class = "confirm_modal_button"
                    v-on:click=exportExcel
                    :loading="loading"
                    :disabled="loading"
                    @click="loader = 'loading'"
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card elevation="2" style="margin-top:20px">
          <div class="row">
            <div class='col-md-12' style="padding-top:0px">
              <div class='table-responsive' style="max-height:500px;overflow-y:scroll">
                <table class="table table-bordered table-hover tableFixHead" id="content" style="border-top:none">
                  <thead>
                    <tr>
                      <th style="text-align:left">TYPE</th>
                      <th>HTG</th>
                      <th>USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="text-left">Cash</th>
                      <td>{{ rows.sales.HTG.values.Cash.amount | formatNumber }}</td>
                      <td>{{ rows.sales.USD.values.Cash.amount | formatNumber }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">Carte</th>
                      <td>{{ rows.sales.HTG.values.Carte.amount | formatNumber }}</td>
                      <td>{{ rows.sales.USD.values.Carte.amount | formatNumber }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">Chèque</th>
                      <td>{{ rows.sales.HTG.values.Cheque.amount | formatNumber }}</td>
                      <td>{{ rows.sales.USD.values.Cheque.amount | formatNumber }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">NATCASH</th>
                      <td>{{ 0 | formatNumber }}</td>
                      <td>{{ 0 | formatNumber }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">Annulations</th>
                      <td>{{ rows.cancellations.HTG.amount | formatNumber }}</td>
                      <td>{{ rows.cancellations.USD.amount | formatNumber }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">Retours</th>
                      <td>{{ rows.returns.HTG.amount | formatNumber }}</td>
                      <td>{{ rows.returns.USD.amount | formatNumber }}</td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>
        </v-card>
        <p
          style="padding:10px;border-radius:3px;background:#d9edf7; border:1px solid #c7e2f0;text-align:left;color:black;margin-top:30px">
          L'application de Caisse Android vous permet de recevoir des paiements en CASH, CARTE ou CHEQUE.Le rapport de
          caisse vous permet de contrôler les montants des différents types de paiements reçu au cours de la période
          choisie. Vous pouvez aussi effectuer des annulations de fiches ou des retours. Notez les éléments suivants
          lorsque vous lisez ce rapport :
        <ul>
          <li>Les fiches annulées visibles dans le rapport sont celles qui ont été initialement créés au cours de la
            période appliquée au rapport.</li>
          <li>Les retours sont considérés comme étant une fiche "normale" avec des quantités négatives.</li>
          <li>Utilisez les filtres dans le cas ou vous aimeriez voir ce rapport pour un magasin ou un vendeur
            spécifique.</li>
        </ul>
        </p>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import moment from 'moment'
import { jsPDF } from "jspdf"
import "jspdf-autotable"

export default {
  name: 'ClosingView',
  methods: {
    load: function () {
      var self = this;
      console.log(this.filter_rate_id)
      this.$axios.get('/reports/closure?from=' + localStorage.debut_periode.toLocaleString() + '&to=' + localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          console.log(response.data.result)
          self.rows = response.data.result
          self.enable = true
        }).catch(function (error) {
          console.log(error)
        })
    },
    reset: function () {
      this.store_id = ''
      this.from = moment(new Date()).format('YYYY-MM-DD')
      this.to = moment(new Date()).format('YYYY-MM-DD')

      this.load()
    },
    exportPDF: function () {
      const doc = new jsPDF('l', 'pt')
      doc.autoTable({ html: '#content', theme: 'grid' })
      doc.save("rapport_fermeture_de_caisse.pdf");
    },
    exportExcel: function(){
      var table = document.getElementById("content");
      var html = table.outerHTML;
      var url = 'data:application/vnd.ms-excel,' + escape(html); 
      var a = document.createElement('a');
      a.href = url;
      a.download = 'rapport_fermeture_de_caisse.xls'; 
      a.click();
    },
    filter: function () {
      var from = localStorage.debut_periode
      var to = localStorage.fin_periode
      var params = '&from=' + from.toLocaleString() + '&to=' + to.toLocaleString()
      if (this.store_id) {
        params = params + '&store=' + this.store_id
      }
      if (this.user_id) {
        params = params + '&user=' + this.user_id
      }
      var self = this;
      this.enable = false
      this.$axios.get('/reports/closure?' + params)
        .then(function (response) {
          self.rows = response.data.result
          self.enable = true
        }).catch(function (error) {
          console.log(error)
        })
    },
    getStores: async function () {
      this.authentication = JSON.parse(localStorage.authentication)
      this.business_id = JSON.parse(localStorage.business).id
      let promise = this.$axios.get('/businesses?id=' + this.business_id)
      let result = await promise;
      this.business_id = result.data[0].id

      var self = this;
      await this.$axios.get('/stores?business_id=' + result.data[0].id)
        .then(function (response) {
          self.stores = response.data
        })
    },
    getUsers: async function () {
      var self = this;
      await this.$axios.get('/users')
        .then(function (response) {
          self.users = response.data
        })
    },
  },
  created() {
    this.filter_rate_id = localStorage.filter_rate_id
    this.load()
    this.getStores()
    this.getUsers()
  },
  data: () => ({
    items: [
      {
        text: 'RAPPORT DE FERMETURE DE CAISSE',
        disabled: false,
        href: '/',
      }
    ],
    rows: [],
    user: [],
    user_id: '',
    enable: false,
    loading: false,
    filter_rate_id: 1,
    fluid: true,
    store_id: '',
    business_id: 0,
    stores: [],
    from: moment(new Date()).format('YYYY-MM-DD'),
    to: moment(new Date()).format('YYYY-MM-DD')
  }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
td {
  font-size: 12px !important;
  vertical-align: middle !important;
}

td span,
th {
  vertical-align: middle !important;
}

th {
  font-size: 12px !important;
}

td,
th {
  text-align: center;
}

.v-select__slot {
  border: 1px solid #ced4da;
  padding: 4px 12px;
  border-radius: 4px;
}

.v-text-field>.v-input__control>.v-input__slot:before {
  display: none;
}

.v-application .v-btn {
  width: 100% !important;
}

.v-text-field {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-breadcrumbs__item {
  color: black !important;
}

.v-text-field__details {
  display: none !important;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
  text-align: center;
  height: 41px !important;
  margin-top: 0px;
}

.v-btn.reset_modal_button {
  background-color: #1867c0 !important;
  color: white !important;
  height: 41px !important;
  margin-top: 0px;
}

.v-btn.export_modal_button {
  background: #F44336 !important;
  color: white !important;
  height: 41px !important;
  margin-top: 0px;
}

.form-control {
  height: auto !important;
  padding: 0.45rem 0.75rem !important;
}
</style>